import { useState, FC, ReactNode } from 'react';
import { GlobalStateContext } from './GlobalStateContext';

interface GlobalStateProviderProps {
    children: ReactNode;
}

export const GlobalStateProvider: FC<GlobalStateProviderProps> = ({ children }) => {
    const [isLogin, setIsLogin] = useState(false);
    return (
        <GlobalStateContext.Provider
            value={{
                isLogin,
                setIsLogin,
            }}>
            {children}
        </GlobalStateContext.Provider>
    );
};