import * as echarts from "echarts";
import { useEffect } from "react";

export function Bitlife() {
    const bitLifeEcharts  = () => {
        const chartDom = document.getElementById('bitlife-charts');
        const myChart = echarts.init(chartDom);
        let option = null;
        option = {
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line',
                    smooth: true
                }
            ],
            tooltip: {
                trigger: 'axis',
                backgroundColor: '#FFDF00',
                textStyle: {
                    color: '#111111',
                    fontWeight: 'bold' // 字体大小
                },
                formatter: function (params: any) {
                    return `<div style="color: #111111">${params[0].data}: ${params[0].axisValue}</div>`
                }
            },
        };

        option && myChart.setOption(option);
    }
    useEffect(() => {
        bitLifeEcharts()
    }, []);
    return (
        <div className={"bitLife-comps"}>
            <div className={"title"}>
                BitLife Class
            </div>
            <div className={"address-main"}>
                <div className={"address-left"}>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            BitLife总数
                        </div>
                        <div className={"value"}>
                            3123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            累计购买BitLife的金额（bnb）
                        </div>
                        <div className={"value price"}>
                            123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            昨日新增BitLife总数                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            昨日BitLife销售金额(bnb)
                        </div>
                        <div className={"value price"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            今日新增BitLife总数
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            今日BitLife销售金额(bnb)
                        </div>
                        <div className={"value price"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            正在工作中的BitLife
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            正在休息中的BitLife
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                </div>
                <div className={"address-right"}>
                    <div id={'bitlife-charts'} className={"echarts-container"}>
                    </div>
                </div>
            </div>
        </div>
    )
}