import "./index.scss"
import { Table } from "antd";
import { useEffect, useState } from "react";
import { User } from '../../api'
export const UserPage = function () {
    const [ dataSource, setDataSource ] = useState([] as any)
    const columns = [
        {
            title: '时间',
            dataIndex: 'dataDate',
            key: 'dataDate',
        },
        {
            title: '新增用户数',
            dataIndex: 'newUserCount',
            key: 'newUserCount',
        },
        {
            title: '次日留存用户',
            dataIndex: 'nextUserCount',
            key: 'nextUserCount',
        },
        {
            title: '三日留存用户',
            dataIndex: 'threeDayUserCount',
            key: 'threeDayUserCount',
        },
        {
            title: '七日留存用户',
            dataIndex: 'sevenDayUserCount',
            key: 'sevenDayUserCount',
        },
        {
            title: '三十日留存用户',
            dataIndex: 'thirtyDayUserCount',
            key: 'thirtyDayUserCount',
        },
        {
            title: '次日留存率',
            dataIndex: 'nextUserPercentage',
            key: 'nextUserPercentage',
        },
        {
            title: '三日留存率',
            dataIndex: 'threeDayUserPercentage',
            key: 'threeDayUserPercentage',
        },
        {
            title: '七日留存率',
            dataIndex: 'sevenDayUserPercentage',
            key: 'sevenDayUserPercentage',
        },
        {
            title: '三十日留存率',
            dataIndex: 'thirtyDayUserPercentage',
            key: 'thirtyDayUserPercentage',
        },
    ];
    useEffect(() => {
        User.getUserActive().then((res: any) => {
            if (res.data.length) {
                setDataSource(res.data)
            }
        })
    }, []);
    return (
        <div className={'user-page'}>
            <div className={"table-container"}>
                <Table rowKey="id" dataSource={dataSource} columns={columns} />
            </div>
        </div>
    )
}