import './App.scss'
import { BrowserRouter } from "react-router-dom";
import GetRouters from "./router"
import { GlobalStateProvider } from "./hook/GlobalStateProvider";
import { ConfigProvider, theme } from "antd";

function App() {
  return (
    <>
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm
            }}
        >
            <GlobalStateProvider>
                <BrowserRouter>
                    <GetRouters />
                </BrowserRouter>
            </GlobalStateProvider>
        </ConfigProvider>
    </>
  )
}

export default App
