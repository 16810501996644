import request from '../utils/request'

const URL = {
    MINING_INFO: 'miningInfo',
    GET_WORK_CELLULA: 'workOrRestCellulas',
    USER_ACTIVE: 'userActive'
}
export default {
    getMiningInfo(ethAddress: any) {
        return request.get(URL.MINING_INFO, {
            params: {
                ethAddress: ethAddress
            }
        })
    },
    getWorkCellula(ethAddress: any) {
        return request.get(URL.GET_WORK_CELLULA, {
            params: {
                ethAddress: ethAddress,
                workStatus: 1,
                sortType: 0,
                pageNum: 1,
                pageSize: 1
            }
        })
    },
    getUserActive() {
        return request.get(URL.USER_ACTIVE, {
            params: {
                key: 'cellula0928666'
            }
        })
    }
}