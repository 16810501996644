import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import Logo from "../../assets/images/layout/logo.png"
import "./index.scss"
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../components/Button.tsx";

export function Layout() {
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        const account = localStorage.getItem('account')
        const password = localStorage.getItem('password')
        if (!account && !password) {
            navigate('/')
        }
    }, []);
    return (
        <div className={"layout-container"}>
            <header>
                <div className={"header-container"}>
                    <img className={"logo"} src={Logo} alt=""/>
                </div>
            </header>
            <div className={"view-container"}>
                {
                    (location.pathname !== '/' && location.pathname !== '/login') ?
                        <aside>
                            <div className={"navigate-container"}>
                                <Button small {...(location.pathname === '/data' ? { active: true } : {})}>
                                    <div onClick={() => navigate('/data')} className={"navigate-item"}>
                                        Data
                                    </div>
                                </Button>
                                <Button small {...(location.pathname === '/user' ? { active: true } : {})}>
                                    <div onClick={() => navigate('/user')} className={"navigate-item"}>
                                        User
                                    </div>
                                </Button>
                                <Button small {...(location.pathname === '/rank' ? { active: true } : {})}>
                                    <div onClick={() => navigate('/rank')} className={"navigate-item"}>
                                        Rank
                                    </div>
                                </Button>
                                <Button small {...(location.pathname === '/search' ? { active: true } : {})}>
                                    <div onClick={() => navigate('/search')} className={"navigate-item"}>
                                        Search
                                    </div>
                                </Button>
                            </div>
                        </aside>
                        : ''
                }
                <main>
                    <Outlet></Outlet>
                </main>
            </div>
        </div>
    )
}