export function Footer() {
    return (
        <div className={"footer-comps"}>
            <div className={"title"}>
                Food Class
            </div>
            <div className={"address-main"}>
                <div className={"address-left"}>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            系统发放的能量总数
                        </div>
                        <div className={"value"}>
                            3123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            用户已领取能量总数
                        </div>
                        <div className={"value price"}>
                            123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            用户已领取能量次数
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            战队总数
                        </div>
                        <div className={"value price"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            已加入战队的用户数
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            未加入战队的用户数
                        </div>
                        <div className={"value price"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            系统社交积分发放总数
                        </div>
                        <div className={"value price"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            邀请任务社交积分发放总数
                        </div>
                        <div className={"value price"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            用户昨日领取能量次数
                        </div>
                        <div className={"value price"}>
                            123123
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}