import "./index.scss"
import * as echarts from 'echarts';
import { useEffect } from "react";

export function Address() {
    const addressEcharts  = () => {
        const chartDom = document.getElementById('address-charts');
        const myChart = echarts.init(chartDom);
        let option = null;
        option = {
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line',
                    smooth: true
                }
            ],
            tooltip: {
                trigger: 'axis',
                backgroundColor: '#FFDF00',
                textStyle: {
                    color: '#111111',
                    fontWeight: 'bold' // 字体大小
                },
                formatter: function (params: any) {
                    return `<div style="color: #111111">${params[0].data}: ${params[0].axisValue}</div>`
                }
            },
        };

        option && myChart.setOption(option);
    }
    useEffect(() => {
        addressEcharts()
    }, []);
    return (
        <div className={"address-comps"}>
            <div className={"title"}>
                Address Class
            </div>
            <div className={"address-main"}>
                <div className={"address-left"}>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            累计地址总数
                        </div>
                        <div className={"value"}>
                            3123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            累计被邀请的地址数
                        </div>
                        <div className={"value"}>
                           123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            昨日新增地址数                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            今日新增地址数
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            昨日喂食地址数
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            昨日领取能量地址数
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            昨日铸造过life的地址数
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                </div>
                <div className={"address-right"}>
                    <div id={'address-charts'} className={"echarts-container"}>
                    </div>
                </div>
            </div>
        </div>
    )
}