import "./index.scss"
import { Address } from "./address.tsx";
import { Bitlife } from "./bitlife.tsx";
import { Food } from "./food.tsx";
import { Footer } from "./footer.tsx";
import { useEffect } from "react";

export function Home() {
    useEffect(() => {
    }, []);
    return (
        <div className={"home-container"}>
            <div className={'address-container'}>
                <Address></Address>
            </div>
            <div className={"bitLife-container"}>
                <Bitlife></Bitlife>
            </div>
            <div className={"food-container"}>
                <Food></Food>
            </div>
            <div className={"footer-container"}>
                <Footer></Footer>
            </div>
        </div>
    )
}