import "./index.scss"
import React from "react";

type ButtonProps = {
    children: React.ReactNode,
    disabled?: boolean,
    small?: boolean,
    mid?: boolean,
    active?: boolean
}

const Button: React.FC<ButtonProps> = ({ children, disabled = false, small = false, mid = false, active = false }) => {
    let buttonClass = 'button-default';

    if (disabled) {
        buttonClass += ' button-disabled';
    }

    if (small) {
        buttonClass += ' small';
    }
    if (mid) {
        buttonClass += ' middle'
    }
    if (active) {
        buttonClass += ' active'
    }
    return <div className={buttonClass}>
        {children}
    </div>;
};

export default Button;