import * as echarts from "echarts";
import { useEffect } from "react";

export function Food() {
    const foodEcharts  = () => {
        const chartDom = document.getElementById('food-charts');
        const myChart = echarts.init(chartDom);
        let option = null;
        option = {
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line',
                    smooth: true,
                    name: '1D',
                    itemStyle: {
                        color: '#F34E00'  // 为了区分，新曲线使用不同的颜色
                    }
                },
                {
                    data: [520, 832, 701, 634, 1190, 1230, 1520],
                    type: 'line',
                    smooth: true,
                    name: '3D',
                    itemStyle: {
                        color: '#e0e500'  // 为了区分，新曲线使用不同的颜色
                    }
                },
                {
                    data: [120, 832, 301, 234, 2190, 230, 520],
                    type: 'line',
                    smooth: true,
                    name: '7D',
                    itemStyle: {
                        color: '#3a4ca7'  // 为了区分，新曲线使用不同的颜色
                    }
                }
            ],
            tooltip: {
                trigger: 'axis',
                backgroundColor: '#008974',
                textStyle: {
                    color: '#F8F0D8',
                    fontWeight: 'bold' // 字体大小
                },
                formatter: function (params: any) {
                    let result = `<div style="color: #F8F0D8">${params[0].axisValueLabel}</div>`;
                    // 迭代每个系列来添加数据
                    params.forEach((param: any) => {
                        // 为每个系列数据添加一个带有颜色标记的项
                        result += `<div style="color: ${param.color};"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>${param.seriesName}: ${param.data}</div>`;
                    });
                    return result;
                }
            },
        };

        option && myChart.setOption(option);
    }
    useEffect(() => {
        foodEcharts()
    }, []);
    return (
        <div className={"food-comps"}>
            <div className={"title"}>
                Food Class
            </div>
            <div className={"address-main"}>
                <div className={"address-left"}>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            1d食物销售总数
                        </div>
                        <div className={"value"}>
                            3123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            1d食物昨日销售数量
                        </div>
                        <div className={"value price"}>
                            123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            3d食物销售总数
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            3d食物昨日销售数量
                        </div>
                        <div className={"value price"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            7d食物销售总数
                        </div>
                        <div className={"value"}>
                            123123
                        </div>
                    </div>
                    <div className={"address-item"}>
                        <div className={"label"}>
                            7d食物昨日销售数量
                        </div>
                        <div className={"value price"}>
                            123123
                        </div>
                    </div>
                </div>
                <div className={"address-right"}>
                    <div id={'food-charts'} className={"echarts-container"}>
                    </div>
                </div>
            </div>
        </div>
    )
}