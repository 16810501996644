import "./index.scss"
import Button from "../../components/Button.tsx";
import { useState } from "react";
import { Table } from "antd";
export function Rank() {
    const [tabStatus, setTabStatus] = useState(1)
    const dataSource = [
        {
            key: '1',
            rank: '1',
            address: '0x93xxxx19d65',
            energy: '999',
        },
        {
            key: '2',
            rank: '2',
            address: '0x93xxxx19d65',
            energy: '888',
        },
    ];

    const columns = [
        {
            title: '排名',
            dataIndex: 'rank',
            key: 'rank',
        },
        {
            title: '用户地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '持有能量数',
            dataIndex: 'energy',
            key: 'energy',
        },
    ];
    return (
        <div className={"rank-container"}>
            <div className={"tab-page"}>
                <Button {...(tabStatus === 1 ? { active: true } : {})}>
                    <div onClick={() => setTabStatus(1)} className={"tab-item"}>
                        持有能量排行榜
                    </div>
                </Button>
                <Button {...(tabStatus === 2 ? { active: true } : {})}>
                    <div onClick={() => setTabStatus(2)} className={"tab-item"}>
                        战队算力排行榜
                    </div>
                </Button>
                <Button {...(tabStatus === 3 ? { active: true } : {})}>
                    <div onClick={() => setTabStatus(3)} className={"tab-item"}>
                        社交积分排行榜
                    </div>
                </Button>
                <Button {...(tabStatus === 4 ? { active: true } : {})}>
                    <div onClick={() => setTabStatus(4)} className={"tab-item"}>
                        邀请排行榜
                    </div>
                </Button>
            </div>
            <div className={"table-container"}>
                <Table dataSource={dataSource} columns={columns} />
            </div>
        </div>
    )
}