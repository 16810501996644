import { useState } from 'react';
import * as XLSX from 'xlsx';
import "./index.scss";
import Button from "../../components/Button.tsx";
import { User } from '../../api'
import { Progress, message } from "antd";

export function Search() {
    const [fileData, setFileData] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const processRow = async (data: any, index: number, results: any) => {
        if (index >= data.length) {
            // 全部处理完毕，创建和下载 Excel
            results.unshift(["Address", "Life Count", "My Energy", "My Qty", "Wait Claim Energy", "Working", "Resting"]);
            const newWs = XLSX.utils.aoa_to_sheet(results);
            const newWb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(newWb, newWs, "Sheet1");
            const excelBuffer = XLSX.write(newWb, { bookType: 'xlsx', type: 'array' });
            const dataBlob: any = new Blob([excelBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
            setFileData(dataBlob);
            return;
        }

        const row = data[index];
        const address = row[0];
        // API 调用和处理逻辑
        try {
            const miningRes: any = await User.getMiningInfo(address);
            if (miningRes.code === 200) {
                const workRes: any = await User.getWorkCellula(address);
                if (workRes.code === 200) {
                    const working = workRes.data.total;
                    const resting = miningRes.data.lifeCount - working;
                    results.push([
                        address,
                        miningRes.data.lifeCount,
                        miningRes.data.myEnergy,
                        miningRes.data.myQty,
                        miningRes.data.waitClaimEnergy,
                        working,
                        resting
                    ]);
                }
            }
        } catch (err) {
            results.push([address, "API Error", "API Error", "API Error", "API Error", "API Error", "API Error"]);
        }

        setCurrentIndex(index + 1);
        setTimeout(() => processRow(data, index + 1, results), 0); // 递归调用处理下一行
    };

    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (evt: any) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type: 'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data: any = XLSX.utils.sheet_to_json(ws, {header: 1});
            setTotalRows(data.length); // 设置总行数
            if (data.length > 500) {
                message.error('Maximum length is 500', 200000)
                throw new Error('Maximum length is 500')
            }
            processRow(data, 0, []); // 开始处理第一行
        };
        reader.readAsBinaryString(file);
    }

    return (
        <div className={"search-container"}>
            <input type="file" onChange={handleFileUpload} accept=".xlsx, .xls" />
            {
                totalRows ?
                    <Progress percent={totalRows ? parseFloat(((currentIndex/totalRows)*100).toFixed(2)) : 0} type="line" />
                    : ''
            }
            {
                fileData &&
                <Button small>
                <div onClick={() => {
                    const url = window.URL.createObjectURL(fileData);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'processed_file.xlsx');
                    document.body.appendChild(link);
                    link.click()}}
                    >
                    Download
                </div>
                </Button>
            }
        </div>
    );
}