import "./index.scss"
import { Input, message } from "antd";
import Button from "../../components/Button.tsx";
import { useGlobalState } from "../../hook/useGlobalState.ts";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

export const Login = () => {
    const { setIsLogin } = useGlobalState()
    const navigate = useNavigate()
    const [account, setAccount] = useState('')
    const [password, setPassword] = useState('')
    const [messageApi, contextHolder] = message.useMessage();

    const Login = () => {
        if (account === 'cellulagameoflife' && password === 'cellulagameoflife0928666') {
            setIsLogin(true)
            localStorage.setItem('account', account)
            localStorage.setItem('password', password)
            navigate('/data')
        } else {
            localStorage.removeItem('account')
            localStorage.removeItem('password')
            messageApi.open({
                type: 'error',
                content: 'The account or password is incorrect',
            });
        }
    }
    useEffect(() => {
        const account = localStorage.getItem('account')
        const password = localStorage.getItem('password')
        if (account && password) {
            setAccount(account)
            setPassword(password)
        }
    }, []);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccount(e.target.value);
    };
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };
    return (
        <div className={"login-container"}>
            {contextHolder}
            <div className={"title"}>Admin</div>
            <div className={"form-container"}>
                <div className={"form-item"}>
                    <span className={'label'}>
                        Account:
                    </span>
                    <Input value={account} onChange={handleInputChange} size={"large"}></Input>
                </div>
                <div className={"form-item"}>
                    <span className={'label'}>
                        Password:
                    </span>
                    <Input.Password value={password} onChange={handlePasswordChange} size={"large"}></Input.Password>
                </div>
                <Button>
                    <div onClick={Login} className={"button"}>Login</div>
                </Button>
            </div>
        </div>
    )
}