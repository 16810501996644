import { useRoutes } from "react-router-dom";
import { Login } from "../view/login/login";
import { Layout } from "../view/layout";
import { Home } from "../view/home/home";
import { ProtectedRoute } from "./ProtectedRoute.tsx";
import { Rank } from "../view/rank/rank.tsx";
import { Search } from "../view/search/search.tsx";
import { UserPage } from "../view/user/user.tsx";

const GetRouters = () => {
    return useRoutes([
        {
            path: "/",
            element: <Layout/>,
            children: [
                {
                    path: '/',
                    element: <Login/>,
                },
                {
                    path: '/login',
                    element: <Login/>
                },
                {
                    path: '/data',
                    element: <ProtectedRoute><Home/></ProtectedRoute>
                },
                {
                    path: '/rank',
                    element: <ProtectedRoute><Rank/></ProtectedRoute>
                },
                {
                    path: '/user',
                    element: <ProtectedRoute><UserPage/></ProtectedRoute>
                },
                {
                    path: '/search',
                    element: <ProtectedRoute><Search/></ProtectedRoute>
                }
            ]
        }
    ])
}
export default GetRouters